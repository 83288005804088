import { graphql } from 'gatsby';
import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import RenderBlocks, { Blocks } from 'utils/RenderModules';

type PageProps = {
  data: GatsbyTypes.PageQueryQuery;
};

const Page: React.FC<PageProps> = ({ data }) => {
  if (!data.datoCmsPage) return null;

  return (
    <>
      <HelmetDatoCms seo={data.datoCmsPage?.seoMetaTags} />
      <RenderBlocks data={data.datoCmsPage.modules as Blocks[]} />
    </>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      id
      title
      seoMetaTags {
        tags
      }
      modules {
        ...CaseBlock
        ...CardBlock
        ...Diagram
        ...Form
        ...Hero
        ...Spacing
        ...IconTextList
        ...TwoTextColumn
        ...MediaComponent
        ...TwoTextImageColumn
        ...Accordion
        ...AccordionEnd
        ...UtilisedNavigation
        ...TitleAndImage
      }
    }
  }
`;
